import React from "react"
import images from "../../assets/images"

import { Link } from "gatsby"
import { Button } from "@material-ui/core"
import "./SlideHeader.css"
import Img from "gatsby-image"

function SlideHeader({ slideBgImage }) {
  return (
    <div className="position-relative">
      <Img
        className="Slide slideblure"
        fluid={slideBgImage.childImageSharp.fluid}
        style={{ width: "100%" }}
      />
      <div className="slidecontenu">
        <div className="container container-seformer">
          <div className="row slideAb">
            <div className="col-md-1 mb-4">
              <img
                src={images.searchSlideHp}
                alt="icone search"
                className="img-fluid icone-search"
              />
            </div>

            <div className="col-md-11 mb-4">
              <h1 className="titleSlide">
                <p className="slideTitre">
                  Nous vous aidons à&nbsp;trouver des candidats
                </p>
                <p className="SlideTitreAb">
                  dans les DROM-COM et l'océan indien
                </p>
              </h1>
            </div>
          </div>
          <div className="row mt-4 divButton">
            <div className="col-md-12">
              <Button className="buttonPub">
                <Link className="cta" to={"/publie-annonce"}>
                  Publier ma première annonce
                </Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SlideHeader
