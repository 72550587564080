import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import Header from "../componets/Header/Header"
import Footer from "../componets/Footer/Footer"
import "./globals.css"
import Abonnement from "../componets/Abonnement/Abonnement"
import SiteMetadata from "../componets/SiteMetadata/SiteMetadata"
import { graphql } from "gatsby"

export const query = graphql`
  {
    hasura {
      seformer_db_subscription(order_by: { price: asc }) {
        subcription_id
        name
        periode
        price
        description
        number_seformer
      }
      seformer_db_option(
        where: { active: { _eq: true } }
        order_by: { name: asc, price: asc }
      ) {
        option_id
        name
        duration
        price
        type
        description
      }
    }
    strapiHomepage {
      about_us {
        description
        id
        title
      }
    }
    logo: file(
      absolutePath: {
        regex: "/images/logo_seformer-f59ecb37be2dc067b743d7ee0a113952.webp/"
      }
    ) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slideBgImage: file(
      absolutePath: {
        regex: "/images/img-slide-hp-6ca02b9b8adb986a503d9ad6e2992964.webp/"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1700, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => {
  const metas = {
    title:
      "Annonce de formation et d'offre de stage à La Réunion, Dom Crom, Océan Indien",
    metatitle: "Formation et stage  La Réunion - Se Former.re",
    description:
      "Annonce d'offres de formation et de stage à La Réunion. Tous secteurs d'activité, pour tous les niveaux, à distance, en alternance, formation en continu ou formation professionnelle",
    keywords:
      "formation, stage, La Réunion, drom, com, annonce, formation à distance, formation en continu, professionnelle, en alternance, Mayotte, Maurice, Océan Indien, Antilles, Madagascar",
  }

  const { data } = props
  const subscriptions = data.hasura.seformer_db_subscription
  const options = data.hasura.seformer_db_option
  const aboutUs = data.strapiHomepage.about_us
  const logo = data.logo
  const slideBgImage = data.slideBgImage;

  return (
    <div className="">
      <SiteMetadata metas={metas} />
      <Header {...props} logo={logo} />
      <Abonnement
        slideBgImage={slideBgImage}
        subscriptions={subscriptions}
        options={options}
        aboutUs={aboutUs}
      />
      <Footer />
    </div>
  )
}
