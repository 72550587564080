import React from "react"
import Topbottom from "../Topbottom/Topbottom"
import { Button } from "@material-ui/core"
import { useStaticQuery, graphql, Link } from "gatsby"
import Carousel from "react-elastic-carousel"

import images from "../../assets/images"
import "./Abonnement.css"
import SlideHeader from "./SlideHeader"

const formatDuration = duration => {
  const digits = duration.match(/\d+/g)
  const letters = duration.match(/[a-zA-Z]+/g)
  let durationUnit = "heures"
  switch (letters[0]) {
    case "w":
      durationUnit = "semaine(s)"
      break
    case "m":
      durationUnit = "mois"
      break
    case "d":
      durationUnit = "jours"
      break
    default:
      durationUnit = "heures"
  }
  return `${parseInt(digits[0], 10)} ${durationUnit}`
}

function Abonnement(props) {
  const { slideBgImage, subscriptions, options, aboutUs } = props;
  return (
    <>
      <SlideHeader slideBgImage={slideBgImage}/>
      <div className="container">
        <div className="rowAb">
          <div className="rowAb">
            <h2 className="title-section AB text-center">
              ABONNEMENT <span>&amp; OPTION</span>
              <img alt="emplois" src={images.imagetitlecontact} />
            </h2>
          </div>
          <Carousel
            className="carossel_abonnement"
            disableArrowsOnEnd
            itemsToShow={4}
            enableSwipe={false}
            enableMouseSwipe={false}
            breakPoints={[
              { width: 1, itemsToShow: 1 },
              { width: 550, itemsToShow: 2 },
              { width: 768, itemsToShow: 3 },
              { width: 1024, itemsToShow: 4 },
            ]}
          >
            {(subscriptions || []).map((subscription, key) => (
              <SubscriptionItem item={subscription} key={key} />
            ))}
          </Carousel>
        </div>
      </div>

      <div className="containerOP">
        <h2 className="title-section AB text-center">
          NOS <span>OPTIONS</span>
          <img alt="emplois" src={images.imagetitlecontact} />
        </h2>
        <div className="row divOption">
          {(options || []).map((item, key) => (
            <OptionItem item={item} key={key} />
          ))}
        </div>
      </div>
      <div className="quinoussommes">
        <Topbottom aboutUs={aboutUs} />
      </div>
    </>
  )
}

const SubscriptionItem = ({ item }) => {
  return (
    <div className="item">
      <div className="Block">
        <h2 className="title-block "></h2>
        <p>{item.name}</p>
        <div className="divPrix">
          <p className="prix">{item.price} &euro;</p>
          <p className="ht">HT</p>
        </div>
        <p>tarif {item.periode}</p>
        <div className="divLi"></div>
        <Button className="buttonAB">
          <Link className="cta" to={process.env.BO_URL}>
            Choisir ce pack
          </Link>
        </Button>
      </div>
    </div>
  )
}

const OptionItem = ({ item }) => {
  return (
    <div className="Option  col-lg-3 col-md-6 col-sm-6 col-xs-12">
      <h2 className="titleOption ">{item.name}</h2>
      <p>{formatDuration(item.duration)}</p>
      <div className="divPrix">
        <p className="prix">{item.price} &euro;</p>
        <p className="ht">HT</p>
      </div>
      <p>tarif par mois</p>
    </div>
  )
}

export default Abonnement
